.panelContainer {
    display: flex;
    flex-direction : column;
    margin: 0 20px;
    padding: 10px;
    flex: 1 1 auto;
}

.panelHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.panelHeader,.panelDescription {
    padding: 5px;
}

.panelDescription {
    font-size: 1.125em;
    line-height: 1.5;
}

.panelHeader > h1 {
    color:#00338D;
    display: block;
    font-size: 1.75em;
    font-weight: bold;
    text-decoration: none;
}

.mgmtContainer {
    color: #00338D;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.systemViewOnly{
    justify-content: left;
}

.subOptionContainer{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.mgmt-item-subarea {
    color: #00338D;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: left;
    padding-left: 40px;
    width: 48%;
}

.linkContainer {
    display: inline-flex;
}

.linkContainer p {
    padding: 5px;
    padding-top: 0;
}
.linkContainer .link {
    text-decoration: underline;
    cursor: pointer;
}

.biz-area, .sys-area {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: left;
    width: 48%;
    padding: 15px;
}

.biz-area .button-main,
.sys-area .button-main {
    text-align: left;
    width: 98%;
    padding: 15px 15px 15px 0px;
}
.biz-area button,
.sys-area button {
    width: 95%;
    min-width: 145px;
    border: none;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
    border-radius: 3px;
    margin: 10px;
    padding: 20px;
    background: #fff;
    color: #00338D;
    text-decoration: none;
    font-size: 100%;
    cursor: pointer;
    height: 63px;
}

.collapse {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: left;
    padding-left: 1.5%;
    width: 100%;
}

.collapse button {
    width: 95%;
    min-width: 145px;
    border: none;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
    border-radius: 3px;
    margin: 10px;
    padding: 20px;
    background: #fff;
    color: #00338D;
    text-decoration: none;
    font-size: 100%;
    cursor: pointer;
    height: 63px;
}

button:hover {
    color: #fff;
    background: #00338D;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.25);
    font-size: 1.2em;
}

.primary-link {
    text-decoration: underline;
    font-size: 18px;
    cursor: pointer;
    display: inline;
    margin-left: 20px;
}

.insights-icon {
    display: inline;
    height: 35px;
    width: 35px;
}
/* services */

.services-area {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: left;
}

.services-link{
    text-align: left;
    display: inline;
    margin-left: 20px;
}

.services-link-area {
    float: left;
    margin-top: 15px;
}

.services-link-area-detail {
    float: left;
    margin-top: 2px;
}

.services-icon {
    height: 50px;
    width: 50px;
}

.services-icon-detail {
    height: 25px;
    width: 25px;
}

.services-subtext {
    margin-left: 20px;
    display: inline;
    font-style: italic;
    color: #000000
}

.primary {
    text-align: left;
}

.sub-option-title {
    border-top: 2px solid black;
    padding: 10px;
    margin-bottom: 20px;
    color: #00338D;
}

.image-only-container {
    display: flex;
    justify-content: center;
}

.image-only {
    height: 80%;
    width: 80%;
}

/* Industry and Practice Standards CSS */
.standardsContainer { 
    margin-left: 20%;
    display: flex;
    margin-top: 3%;
}

.standardsContainer .left-side { 
    display: flex;
    flex-wrap: nowrap;
}

.standardsContainer .right-side { 
    width: 18%;
    margin-left: 1%;
    background-color: #002a4d;
    overflow: auto;
    display: flex;
    flex-direction: column;
}

.standardsBreadcrumb { 
    margin-left: 1%;
    margin-top: 1%;
}

.moduleDetailedInformation { 
    display: none;
}

.moduleBox:hover { 
    background-color: rgb(65, 65, 255) !important;
    color: white;
}

.moduleBox:hover > .moduleInformationTitle { 
    display: none;
}

.moduleBox:hover > .moduleDetailedInformation { 
    display: block;
    font-weight: normal;
    font-size: 12pt;
    margin: 9%;
    line-height: 125%;
}

.middle { 
    margin-left: 5% !important;
    margin-right: 5%;
}

.moduleInformationTitle .main-text { 
    color: rgb(26, 26, 200);
    padding-top: 2%;
    padding-bottom: 5%;
    margin-top: 20%;
    font-weight: 550;
    font-style: normal;
    text-align: center;
    font-size: 27pt;
}

.moduleInformation { 
    display: block;
    font-size: 17pt;
    padding-left: 13%;
    padding-right: 10%;
    color: #263373;
    font-weight: bold;
    text-align: center;
}

.roll-over-description { 
    margin-top: 0%;
}

.linksContainer { 
    padding-top: 1%;
    margin-top: 10%;
    margin-left: 10%;
    color: white;
    font-family: "Univers45", Arial, sans-serif;
}


.primaryLinkResource {
    margin-top: 10%;
    width: 85%;
    font-weight: normal;
    font-size: 13pt;
}

.linksContainer a { 
    text-decoration: none;
}

.secondaryLinkResource { 
    margin-top: 10%;
    width: 85%;
    font-size: 13pt;
}

.lightblue { 
    color: rgb(161, 231, 255);
}

.KPMG-bold { 
    font-family: "Univers75", Arial, sans-serif;
    color: white;
    font-size: 30pt;
    font-weight: 500;
}

/* iPad Size*/ 
@media only screen and (max-width: 1400px) {
    .standardsContainer { 
        margin-left: 18%;
        display: flex;
        margin-top: 3%;
    }

    .industryPracticeStandardsBackground { 
        max-width: 51%;
        height: 40%;
        z-index: 1;
        position: absolute;
    }

    .moduleInformationTitle .main-text { 
        color: rgb(26, 26, 200);
        padding-top: 2%;
        padding-bottom: 5%;
        margin-top: 20%;
        font-family: "Univers45", Arial, sans-serif;
        font-weight: bold;
        font-style: normal;
        text-align: center;
        font-size: 15pt;
    }

    .moduleInformation { 
        display: block;
        font-size: 13pt;
        padding-left: 13%;
        padding-right: 10%;
        color: #263373;
        font-weight: bold;
        text-align: center;
    }

    .KPMG-bold { 
        font-family: "Univers75", Arial, sans-serif;
        color: white;
        font-size: 18pt;
        font-weight: bold;
    }

    .moduleBox:hover > .moduleDetailedInformation { 
        display: block;
        font-weight: normal;
        font-size: 9pt;
        margin: 9%;
        line-height: 125%;
    }

    .primaryLinkResource {
        margin-top: 10%;
        width: 100%;
        font-weight: normal;
        font-size: 10pt;
    }
    
    .secondaryLinkResource { 
        margin-top: 10%;
        width: 85%;
        font-size: 10pt;
    }

    .moduleBox {
        margin-top: 35%;
        margin-left: 0%;
        width: 17vw;
        height: 25vh; 
        z-index: 2;
        background-color: rgb(255, 255, 255);
        overflow: auto;
    }
    
    .standardsContainer .right-side { 
        width: 20%;
        height: 51vh;
        margin-left: 1%;
        background-color: #002a4d;
        overflow: auto;
        display: flex;
        flex-direction: column;
    }
}

/* MacBook Size*/ 
@media only screen and (min-width: 1400px) {
    .standardsContainer { 
        margin-left: 18%;
        display: flex;
        margin-top: 3%;
    }

    .industryPracticeStandardsBackground { 
        max-width: 54%;
        height: 40%;
        z-index: 1;
        position: absolute;
    }

    .moduleBox {
        margin-top: 25%;
        margin-left: 0%;
        width: 18vw;
        height: 30vh; 
        z-index: 2;
        background-color: white;
        overflow: auto;
    }
}

/* Anything Larger*/ 
@media only screen and (min-width: 1800px) {
    .industryPracticeStandardsBackground { 
        max-width: 51%;
        height: 40%;
        z-index: 1;
        position: absolute;
    }

    .moduleBox {
        margin-top: 25%;
        margin-left: 0%;
        width: 17vw;
        height: 30vh; 
        z-index: 2;
        background-color: white;
        overflow: auto;
    }
}