.admin-content-container {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  padding: 1rem;
}

.admin-content-container > form {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
}

.admin-content-container > form > textarea {
  width: 100%;
  flex: 1 1 auto;
}

.admin-content-container-msg {
  justify-content: center;
  align-items: center;
}

.admin-controls {
  margin: 1rem 0;
}

.admin-controls > button {
  margin-right: .25rem;
}
