.controlsContainer {
  display: flex;
  flex-direction: row;
  padding-top: 10px;
  align-items: center;
}

.controlsContainer .controls {
  flex: 1;
  display: flex;
}

.controlsContainer .controls.left {
  justify-content: flex-start;
}

.controlsContainer .controls.center {
  justify-content: center;
}

.controlsContainer .controls.right {
  justify-content: flex-end;
}
