.cards {
    width: 22%;
    min-width: 162px;
    height: 200px;
    color: black;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    margin: 10px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
    transition: all .15s ease-in-out;
    background-color: white;
}

.cards.no-corner-icon { 
    padding-top: 1%;
}

.cards .marginTop{ 
    margin-top: 10%;
}

.cards.no-icon {
    height: 50px;
}


.cards.width-4 {
    width: 92%;
}

.cards.width-3 {
    width: 68%;
}

.cards:hover {
    color: white;
    background-color: #00338D;
    cursor: pointer;
}

.cardIcon {
    height: 50%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}


.cardIcon img {
    height: 70%;
    width: 70%;
}

.cards svg {
    fill: #00338D;
    width: 30%;
}

.cards:hover svg {
    fill: #0091da;
}

.cardDescription {
    height: 30%;
    font-size:1em;
    line-height: 1;
    font-weight: bold;
    text-align: center;
}

.card-placeholder {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 22%;
    min-width: 162px;
    justify-content: center;
    align-items: center;
    margin: 10px;
    padding:10px;
}

@media only screen and (max-width: 1099px) {
    .cards {
      width: 40%;
    }
    .cards.width-4,
    .cards.width-3 {
        width: 84%;
    }

    .card-placeholder {
        margin: 0;
        padding: 0;
    }
  }