.moduleIcon {
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 12%;
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: 35%
}

.cornerImg {
    height: 35%;
    float: left;
    position: relative; 
    right: 0; 
    bottom: 0;
}

.module svg {
    fill: #00338D;
    width: 30%;
}
.cards:hover {
    color: white;
    background-color: #00338D;
    cursor: pointer;
}

.moduleDescription {
    height: 30%;
    line-height: 1;
    font-weight: bold;
    text-align: center;
}