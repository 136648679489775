.footerContainer {
    display: flex;
    flex-direction : row;
    margin: 0 auto;
    border-top: 3px solid #dddddd;
    overflow: none;
}

.footerDescription {
     padding: 15px 20px;
}