
.description {
  padding: 0px 5px;
  font-size: 1.5em;
  line-height: 1.2;
}

.description p {
  font-size: .8em;
  line-height: 1.2;
}

.dashboard-content {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: auto;
  width: 75%; 
}

.quadrant:hover { cursor: pointer;}
#insights:hover .st0 {fill:#004085 !important}
#services:hover .st6 {fill:#186b9a !important}
#industry:hover .st12 {fill:#02908d !important}
#tools:hover .st9 {fill:#3c9e42 !important}

.chartDescription {
  position: absolute;
  width: 25vw;
}

.tooltip-container {
  min-width: 15vw;
  color: black;
  margin: 0 10%;
  font-size: 1rem;
  padding: 10px 10px 10px 20px;
  border-top-left-radius: 18px;
  border-top-right-radius: 18px;
  border-bottom-left-radius: 18px;
  border-bottom-right-radius: 18px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
}
.tooltip-hidden {
  display: none;
}
.tooltip-container div {
  padding: 10px;
  color: white;
}

.top-left h3,
.top-right h3,
.bottom-right h3,
.bottom-left h3 {
  color: white;
  line-height: 1;
  font-size: 1.2em;
}

.top-left {
  background-color: #356CB5;
  opacity: 0.8;
  width: 30%;
  margin-right: 90px;
}
.top-right {
  margin-top: 4vh;
  margin-left: 80vw;
  background-color: #2090CF;
  opacity: 0.8;
  width: 30%;
}
.bottom-left {
  margin-top: 39vh;
  background-color: #00A3A0;
  opacity: 0.8;
  width: 30%;
}
.bottom-right {
  margin-left: 80vw;
  margin-top: 39vh;
  background-color: #42B049;
  opacity: 0.8;
  width: 30%;
}

@media only screen and (max-width: 1400px) {
  .bottom-left,
  .bottom-right {
    margin-top: 34vh;
  }
}

@media only screen and (max-width: 1100px) {
  .bottom-left,
  .bottom-right {
    margin-top: 13vh;
  }
}
.svg-container {
  width: 100vw;
  padding: 0;
}
.panelContainer {flex:0 0 auto;} 

.svg-wrapper {
    position:relative;
    margin: auto;
    padding:5px;
    width:80%;
}

.welcome-grid {
    display:grid;
    grid-template-rows:auto auto;
    grid-template-columns:50% 50%;
}

.svg-wrapper .quadrant {
    border:5px solid #fff;
}
.svg-wrapper.home .quadrant svg {width:100%;}
.svg-wrapper .welcome-logo {
    position:absolute;
    left:50%;
    top:50%;
    margin:-6vw 0 0 -6vw;
    width: 12vw;
    height: 12vw;
    max-width: 100%;
    max-height: 100%;
}

.welcome-logo img {
  width:100%;
}