html,
body {
  min-height: 100vh;
  overflow-x: hidden;
}

body {
  font-weight: 300;
}

img {
  color: transparent;
  font-size: 0;
  vertical-align: middle;
  -ms-interpolation-mode: bicubic;
}

svg {
  overflow: hidden;
}

header {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 20px;
  border-bottom: 5px solid #0091da;
}

main {
  padding: 20px 0 40px;
}

main h1 {
  margin: 20px 20px 0;
  font-size: 2em;
}

.card-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 20px 0;
  padding: 0 20px;
}

.card-wrapper .card {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 20px;
  width: 100%;
  background: #fff;
  -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
  text-decoration: none;
  text-align: center;
  -webkit-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
}

.card-wrapper .card .icon {
  padding: 20px 0 0;
}

.card-wrapper .card .icon svg {
  width: 30%;
}

.card-wrapper .card .icon svg path,
.card-wrapper .card .icon svg rect,
.card-wrapper .card .icon svg polygon {
  fill: #00338d;
  -webkit-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
}

.card-wrapper .card h3 {
  margin: 30px 0 20px;
  font-size: 1.5em;
  color: #343a40;
  -webkit-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
}

.card-wrapper .card:hover {
  background: #00338d;
  -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.25);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.25);
}

.card-wrapper .card:hover .icon svg path,
.card-wrapper .card:hover .icon svg rect,
.card-wrapper .card:hover .icon svg polygon {
  fill: #0091da;
}

.card-wrapper .card:hover h3 {
  color: white;
}

.breadcrumbs {
  margin: 0 10px;
  font-size: 0.85em;
  color: #666;
}

.breadcrumbs span {
  display: inline-block;
  padding: 0 15px;
}

.breadcrumbs a {
  color: #0091da;
  cursor: pointer;
}

.breadcrumbs a:hover {
  color: #43b02a;
}

.sequence-link {
  padding: 30px 20px 0;
  text-align: right;
}

.module-details {
  margin: 20px 0 0;
  padding: 0 10px;
}

.module-details .col_3 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.module-details a {
  display: block;
  margin: 10px;
  padding: 20px;
  background: #00338d;
  color: white;
  text-decoration: none;
  -webkit-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
  border-radius: 3px;
}

.module-details a:hover {
  background: #0091da;
}

.svg-container {
  padding: 20px;
}

.svg-container svg,
.svg-container img {
  width: 100%;
}

footer {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 40px 20px;
  border-top: 3px solid #ddd;
}

footer p {
  font-size: 0.85em;
}
.bodyContainer {
  min-height: 80vh;
  background-color: whitesmoke;
  display: flex;
  flex-direction: column;
}

/* Overall CSS for the Landing Page */ 
.landing-page { 
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  font-weight: bold;
  font-family: 'Open Sans Condensed', sans-serif;
  color: black;
  letter-spacing: 0px;
  font-size: 25px;
}

.landing-page-row { 
  flex: 1;
}

.full-length-col { 
  display: flex;
  flex-direction: column;
}

.landing-kera-logo { 
  height: 90px;
  width: 100px;
}

.landing-page-insights { 
  background-color: #0C233C;
}

.landing-page-services { 
  background-color: #00338D;
}

.landing-page-industry { 
  background-color: #1E49E2;
}

.landing-page-tools { 
  background-color: #7213EA;
}

.landing-blue-description { 
  color: #263373;
}

.unhighlighted { 
  opacity: .5;
  color: white;
}

.unhighlighted:hover { 
  opacity: 1;
  text-decoration: none;
}

.row.quadrants-row { 
  /* Used to match the carousel row and the quadrant row nicely */ 
  margin-left: 0;
  --bs-gutter-x: 0rem;
}

.stack { 
  flex: 1;
}

/* iPad Size*/ 
@media only screen and (max-width: 1200px) {
  .logo-intro { 
    margin-left: 30%;
    margin-top: 20%;
    font-size: 1.75em;
    justify-content: flex-end;
  }
  
  .landing-page-greeting { 
    width: 90%;
    text-align: center;
    margin-top: 20%;
    margin-left: 10%;
    text-align: left;
    font-size: 1.5rem;
  }
  
  .landing-page-description { 
    width: 90%;
    text-align: left;
    margin-top: 40%;
    margin-left: 10%;
    font-size: 1.25rem;
    font-weight: normal;
  }
  
  .landing-page-tile-description { 
    margin-top: 12%;
    margin-left: 10%;
    width: 14vw;
    height: 10%;
    text-align: center;
    font-size: 1.25rem;
  }
  
  .landing-page-tile-description.smaller { 
    /* Tools and Assets div had to be smaller in width for the 'explore' buttons to be same position */ 
    width: 8vw;
    margin-left: 22%;
  }

  .carousel { 
    width: 700px;
    height: 350px;
  }
  
  .landing-page-icons { 
    margin-left: 35%;
    margin-top: 20%;
    height: 8.5vh;
    width: 5vw;
  }
  
  .explore-button {
    margin-top: 25%;
    margin-left: 20%;
    margin-bottom: 5%;
    padding: 3% 20%;
    border-radius: 40px 40px 40px 40px;
    border: 2px solid white;
    font-size: 1rem;
    display: inline-block;
    text-decoration: none;
  }
}

/* MacBook Size*/ 
@media only screen and (min-width: 1200px) {
  .logo-intro { 
    margin-left: 30%;
    margin-top: 20%;
    font-size: 1.75em;
    justify-content: flex-end;
  }
  
  .landing-page-greeting { 
    width: 80%;
    text-align: center;
    margin-top: 20%;
    margin-left: 17%;
    text-align: left;
    font-size: 1.75rem;
  }
  
  .landing-page-description { 
    width: 80%;
    text-align: left;
    margin-top: 40%;
    margin-left: 17%;
    font-size: 1.5rem;
    font-weight: normal;
  }
  
  .landing-page-tile-description { 
    margin-top: 15%;
    margin-left: 17%;
    width: 10vw;
    height: 10%;
    text-align: center;
    font-size: 1.5rem;
  }
  
  .landing-page-tile-description.smaller { 
    /* Tools and Assets div had to be smaller in width for the 'explore' buttons to be same position */ 
    width: 8vw;
    margin-left: 22%;
  }

  .carousel { 
    /* Images must be in these dimensions */
    width: 1200px;
    height: 450px;
  }
  
  .landing-page-icons { 
    margin-left: 35%;
    margin-top: 25%;
    height: 8.5vh;
    width: 4.5vw;
  }
  
  .explore-button {
    margin-top: 20%;
    margin-left: 22%;
    margin-bottom: 14%;
    padding: 3% 20%;
    border-radius: 40px 40px 40px 40px;
    border: 2px solid white;
    font-size: 1rem;
    display: inline-block;
    text-decoration: none;
  }
}

/* Anything Larger*/ 
@media only screen and (min-width: 1800px) {
  .logo-intro { 
    margin-left: 30%;
    margin-top: 20%;
    font-size: 1.75em;
    justify-content: flex-end;
  }
  
  .landing-page-greeting { 
    width: 80%;
    text-align: center;
    margin-top: 10%;
    margin-left: 17%;
    text-align: left;
    font-size: 1.75rem;
  }
  
  .landing-page-description { 
    width: 80%;
    text-align: left;
    margin-top: 40%;
    margin-left: 17%;
    font-size: 1.5rem;
    font-weight: normal;
  }
  
  .landing-page-tile-description { 
    margin-top: 15%;
    margin-left: 17%;
    width: 10vw;
    height: 10%;
    text-align: center;
    font-size: 1.5rem;
  }
  
  .landing-page-tile-description.smaller { 
    /* Tools and Assets div had to be smaller in width for the 'explore' buttons to be same position */ 
    width: 5vw;
    margin-left: 30%;
  }

  .carousel { 
    /* Images must be in these dimensions */
    width: 1200px;
    height: 450px;
  }
  
  .landing-page-icons { 
    margin-left: 35%;
    margin-top: 15%;
    height: 8.5vh;
    width: 4.5vw;
  }
  
  .explore-button {
    margin-top: 10%;
    margin-left: 22%;
    /* margin-bottom: 10%; */
    padding: 3% 20%;
    border-radius: 40px 40px 40px 40px;
    border: 2px solid white;
    font-size: 1rem;
    display: inline-block;
    text-decoration: none;
  }
}