.detailContainer {
    /* background-color: white; */
    overflow: auto;
    display: flex;
}
.detailContainer img {
    height: 100%;
    width: 100%;
}
.detailContainer object {
    flex: 1 1 auto;
}
.buttonContainer {
    padding-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.no-data {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1 1 auto;
}
