.content-container {
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 95vw;
  height: 80vh;
  padding: 0px 1%;
  justify-content: center;
}

.content-container-msg {
  align-items: center;
}

header {
  flex: none;
}

h1 {
  margin: 0 0 0.1em 0;
  font-size: 2em;
}

h2 {
  margin: 0 0 0.25em 0;
  font-size: 1.5em;
  font-weight: normal;
}

.hud-home {
  flex: none;
}

.modernization-link,
.modernizatoin-link a {
  color: #007bff;
}

.modernization-link:hover,
.modernizatoin-link:hover a {
  color: #0056b3;
}

/* Meatball Chart */

#meatballChart {
  flex: auto;
  margin: 20px 0 40px 30px;
  width: calc(100% - 30px);
  overflow: visible;
}

.phase-bg {
  opacity: 0.25;
}

.phase-label {
  transform: rotate(-90deg);
  fill: #445566;
  font-weight: 500;
}

.year-line {
  stroke: #d0d1d0;
  stroke-width: 2px;
  stroke-dasharray: 5px;
}

.year-line:first-child {
  display: none;
}

svg.year {
  overflow: visible;
  fill: #445566;
  text-shadow: 0 0 5px white;
  font-weight: 500;
}

.project,
.projectSI {
  overflow: visible !important;
  cursor: pointer;
  font-weight: 500;
}

.project > circle.outer,
.projectSI > circle.outer {
  opacity: 0.5;
}

.project > circle.inner,
.projectSI > circle.inner {
  opacity: 0.25;
}

.project > circle.stroke,
.projectSI > circle.stroke {
  stroke: #ffffff;
  stroke-width: 2px;
  fill: none;
  opacity: 0.9;
}

.project > circle.stroke.buy,
.projectSI > circle.stroke.buy {
  stroke: #0072bb;
}

.project.active > circle.stroke,
.projectSI.active > circle.stroke {
  stroke-width: 3px;
}

.project > rect.outer,
.projectSI > rect.outer {
  opacity: 0.5;
}

.project > rect.inner,
.projectSI > rect.inner {
  opacity: 0.25;
}

.project > rect.stroke,
.projectSI > rect.stroke {
  stroke: #adb5bd;
  stroke-width: 2px;
  fill: none;
  opacity: 0.9;
}

.project > rect.stroke.buy,
.projectSI > rect.stroke.buy {
  stroke: #0072bb;
}

.project.active > rect.stroke,
.projectSI.active > rect.stroke {
  stroke-width: 3px;
}

.project text,
.projectSI text {
  font-size: 0.8em;
}

path.connector {
  fill: none;
  stroke-width: 2px;
  stroke: #914a4a;
  opacity: 0.8;
  marker-end: url(#connector-arrow);
}

path.connector.soft {
  fill: none;
  stroke-width: 2px;
  stroke: #914a4a;
  stroke-dasharray: 2;
  opacity: 0.8;
  marker-end: url(#connector-arrow);
}

marker {
  fill: #914a4a;
}

.total-budget {
  flex: none;
  font-size: 1.35em;
  text-align: center;
  color: #445566;
}

.footnote {
  display: block;
  position: absolute;
  bottom: 0;
  right: 0;
  font-size: 0.8em;
  text-align: right;
  color: #445566;
}

.chart-key {
  flex: none;
  text-align: center;
  color: #0072bb;
  margin-top: 15px;
}

.chart-key .line {
  display: inline-block;
  position: relative;
  top: 8px;
  width: 15px;
  height: 0px;
  margin: 0 5px;
}

.chart-key .line:first-child {
  margin-left: 0;
}

.chart-key .hard {
  border-bottom: 2px solid #914a4a;
}

.chart-key .soft {
  border-bottom: 2px dotted #914a4a;
}

.chart-key .build {
  border-bottom: 2px solid #ffffff;
  box-shadow: 0 0 5px #555;
}

.chart-key .buy {
  border-bottom: 2px solid #007bff;
}

.controlsContainer .controls.left {
  margin-left: 20px;
}

.content-container .controls.left > button {
  margin-right: .25rem;
}

.content-container .controls.right > button {
  margin-right: .25rem;
}

.module-link:hover {
  text-decoration: underline;
}

.edit-form input[type='number'] {
  width: 50%;
}

@media screen and (min-width: 1520px) {
  body {
    background-size: contain;
  }
}

@media screen and (max-width: 900px) {
  .project text {
    font-size: 0.7em;
  }
}
