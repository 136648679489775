.panelContainer {
    display: flex;
    flex-direction : column;
    margin: 0 20px;
    padding: 10px;
    flex: 1 1 auto;
}
.panelHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.panelHeader,.panelDescription {
    padding: 5px;
}
.panelDescription {
    line-height: 1.5;
}
.panelHeader > h1 {
    color:#00338D;
    display: block;
    font-size: 1.75em;
    font-weight: bold;
    text-decoration: none;
}

.cardContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.moduleContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}
.detailContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: left;
}

.moduleContainer .module {
    width: 22%;
    min-width: 145px;
    border: none;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
    transition: all .15s ease-in-out;
    border-radius: 3px;
    margin: 10px;
    margin-bottom: 2%;
    background: #fff;
    color: #00338D;
    text-decoration: none;
    font-size: 120% !important;
    cursor: pointer;
    height: 25vh;
}

.moduleContainer .module.full {
    width: 91%;
    min-width: 145px;
    border: none;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
    transition: all .15s ease-in-out;
    border-radius: 3px;
    margin: 10px;
    padding: 20px;
    background: #fff;
    color: #00338D;
    text-decoration: none;
    font-size: 100%;
    cursor: pointer;
    height: 63px;
}

.moduleContainer .module:hover {
    color: #fff;
    background: #01328e;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.25);
}

@media only screen and (max-width: 699px) {
    .moduleContainer {
        flex-direction: column;
        flex-wrap: wrap;
    }

    .moduleContainer .module{
        width: 100%;
    }
}