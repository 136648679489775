.headerContainer {
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    padding: 15px 20px;
    border-bottom: 5px solid #0091da;
    color: #00338D;
}

.headerContainer h1 {
    color:#00338D;
    display: block;
    padding: 10px 0 0;
    font-size: 1.75em;
    font-weight: bold;
    text-decoration: none;
}
.headerContainer h1:hover {
    color:#0091da;
}

.logo img {
    height: 50px;
    width: 60px;
    padding-right: 10px;
}

.brand {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}